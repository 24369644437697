import React, { Component } from 'react';
import Brand from '../models/Brand';
import Site from '../models/Site';
import { connect } from 'react-redux';
import { mapEdgesToNodes } from '../utils/utils';
import { navigate } from 'gatsby';
import { userLocation } from '../utils/userLocation';

class Base extends Component {
  constructor(props) {
    super(props);

    this.resolveSite = this.resolveSite.bind(this);
  }

  componentDidMount() {
    this.resolveSite();
  }

  // prettier-ignore
  async resolveSite() {
    const { location, pageContext } = this.props;

    const brands = mapEdgesToNodes({
      edges: pageContext.brands
    }).map((brand) => new Brand(brand));

    const sites = mapEdgesToNodes({
      edges: pageContext.sites
    }).map((site) => new Site(site));

    const defaultBrand = brands.find((brand) => brand.brandUID === process.env.GATSBY_SITE_BRAND);

    const pathParts = location.pathname.split('/').filter((part) => part);
    let brand = brands.find((brand) => brand.brandUID === pathParts[0]);
    let site = null;

    if (brand) {
      site = sites.find((site) => site.brandUID === brand.brandUID && site.siteUID === pathParts[1]);
    } else {
      brand = defaultBrand;
      site = sites.find((site) => site.brandUID === brand.brandUID && site.siteUID === pathParts[0]);
    }

    if (!site) {
      const locationSite = await userLocation(brand, null, sites);
      navigate(locationSite.path);
    }
  }

  render() {
    return null;
  }
}

export default connect((state) => ({
  userLocation: { ...state.userLocationService },
}))(Base);
